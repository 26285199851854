



































import { Component, Vue } from 'vue-property-decorator';
import { Container } from 'inversify';
import { AuthError } from 'msal';
import container from '../../injection/Container';
import { AuthService } from '../../msal/AuthService';
import ConnectSetup from '../../interfaces/api/interface/ConnectSetup';

@Component
export default class Dashboard extends Vue {
  private apiUrl = process.env.NODE_ENV === 'production' ? 'https://api.sprkl.app' : process.env.VUE_APP_API_URL;

  private diContainer: Container = container;

  public busAuthService: AuthService;

  stripeSignUpUrl = '';

  stripeSignUpDisplayed = false;

  // snackbar
  private snackbar = false;

  private snackbarContent = '';

  private snackbarTimeout = 4000;

  private snackbarColor = 'success';

  dataLoading = false;

  constructor() {
    super();
    this.busAuthService = this.diContainer.getNamed<AuthService>('AuthService', 'business');
  }

  async mounted() {
    await this.mountEdit();
  }

  async mountEdit() {
    if (this.$store.state.accessToken === undefined) {
      try {
        const response = await this.busAuthService.login();
        this.$store.commit('authSuccess', this.busAuthService.getAccount());
        this.$store.commit('accessToken', response.idToken.rawIdToken);
      } catch (e) {
        if (e instanceof AuthError) {
          //   console.log(e);
        } else {
          throw e;
        }
      }
      this.mountEdit();
      return;
    }

    fetch(`${this.apiUrl}/api/stripe/connect/setup`, {
      method: 'post',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.$store.state.accessToken}`
      })
    })
      .then(response => {
        if (response.status < 200 && response.status > 299) {
          throw new Error(`Response Status: ${response.status}\nMessage: ${response.statusText}`);
        }
        if (response.status === 200) {
          return response.json() as Promise<ConnectSetup>;
        }
        if (response.status === 204) {
          return null;
        }
        throw new Error('Unrecognised status code');
      })
      .then((data?: ConnectSetup | null) => {
        if (data) {
          this.stripeSignUpUrl = data.url;
          this.stripeSignUpDisplayed = true;
        }
        this.dataLoading = false;
      })
      .catch(err => {
        this.dataLoading = false;
        this.snackbar = true;
        this.snackbarContent = 'There was an error loading the data please try again.';
        this.snackbarColor = 'error';
      });
  }
}
